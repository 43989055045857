<template>
    <div>
        <div class="commonInputTopBox">
            <infoHeader
                :content_name="'升降机详细信息： ' + infoData.lifter_product.lifter_name"
            />
            <div style="float: right; margin-top: -65px">
                <el-button type="danger" @click="deleteLifter"
                    >删除该升降机</el-button
                >
            </div>
        </div>
        <!-- 升降机信息 -->
        <el-descriptions
            title="升降机信息"
            direction="vertical"
            :column="3"
            border
        >
            <el-descriptions-item label="升降机ID">{{
                infoData.lifter_id
            }}</el-descriptions-item>
            <el-descriptions-item label="升降机名称">{{
                infoData.lifter_product.lifter_name
            }}</el-descriptions-item>
            <el-descriptions-item label="升降机型号">{{
                infoData.lifter_product.lifter_model
            }}</el-descriptions-item>
            <el-descriptions-item label="升降机安装位置">{{
                infoData.Installation_address
            }}</el-descriptions-item>
            <el-descriptions-item label="升降机安装层高">{{
                infoData.floor
            }}</el-descriptions-item>
        </el-descriptions>

        <!-- 切换选项卡 -->
        <el-tabs
            v-model="activeName"
            type="card"
            style="margin-top: 30px"
            @tab-click="handleTabClick"
        >
            <el-tab-pane label="升降机资料" name="2">
                <!-- 升降机 -->
                <el-descriptions title="升降机资料，或升降机手册，附件">
                    <template slot="extra">
                        <el-button type="primary" size="small"
                            >创建新的资料</el-button
                        >
                    </template>
                </el-descriptions>

                <el-table
                    :data="tableData.product_material_list"
                    style="width: 100%"
                >
                    <el-table-column prop="id" label="系统id" width="180" />
                    <el-table-column
                        prop="material_title"
                        label="资料标题"
                        width="180"
                    />
                    <el-table-column prop="create_time" label="创建时间" />
                    <el-table-column
                        prop="update_time"
                        label="最近一次更新时间"
                    />
                    <el-table-column label="操作" fixed="right" width="180">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleEdit(scope.row)"
                                >重命名</el-button
                            >
                            <el-button
                                size="mini"
                                @click="toMaterial(scope.row)"
                                >查看资料</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <!-- 添加异常组件弹窗 -->
        <el-dialog title="添加异常" :visible.sync="dialogErrorFormVisible">
            <el-form :model="abnormalData">
                <el-form-item label="异常错误码" :label-width="'120px'">
                    <el-input
                        v-model="abnormalData.error_code"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="异常信息" :label-width="'120px'">
                    <el-input
                        v-model="abnormalData.error_info"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogErrorFormVisible = false"
                    >取消</el-button
                >
                <el-button type="primary" @click="addAbnormal">确认</el-button>
            </div>
        </el-dialog>

        <!-- 编辑异常组件弹窗 -->
        <el-dialog title="编辑异常" :visible.sync="dialogErrorFormVisibleEdit">
            <el-form :model="editData">
                <el-form-item label="异常错误码" :label-width="'120px'">
                    <el-input
                        v-model="editData.error_code"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="异常信息" :label-width="'120px'">
                    <el-input
                        v-model="editData.error_info"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogErrorFormVisibleEdit = false"
                    >取消</el-button
                >
                <el-button type="primary" @click="editAbnormal">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    lifter_manage_get,
    lifter_manage_delete,
    lifter_manage_lifter_error_create,
    lifter_manage_lifter_error_update
} from '@/api/manage/operation/lifter_manage/lifter_manage.js';

export default {
    name: 'LifterInfo',
    components: {  },
    data() {
        return {
            infoData: {lifter_product:{}},
            lifter_id: null,
            activeName: '2',
            dialogErrorFormVisible: false,
            dialogErrorFormVisibleEdit: false,
            abnormalData: {
                lifter_id: 1
            },
            editData: {},
            tableData: {
                lifterFault: []
            },
            tableColumns: [
                {
                    prop: 'id',
                    label: '系统ID',
                    minWidth: '10%'
                },
                {
                    prop: 'error_code',
                    label: '异常代码',
                    minWidth: '10%'
                },
                {
                    prop: 'error_info',
                    label: '异常原因',
                    minWidth: '20%'
                }
            ],
            tableButton: [
                { label: '处理方法', type: 'primary', action: 'toResolvent' },
                { label: '编辑异常', type: 'info', action: 'openEdit' }
            ]
        };
    },
    created() {
        this.lifter_id = this.$route.query.id;
        this.getLifterInfo(this.lifter_id);
    },
    methods: {
        getLifterInfo(id) {
            lifter_manage_get({ lifter_id: id }).then(res => {
                this.infoData = res.data;
            });
        },
        deleteLifter() {
            lifter_manage_delete({
                lifter_id: this.infoData.lifter_id
            }).then(res => {
                if (res.code == 200) {
                    this.$router.go(-1);
                }
            });
        },
        addAbnormal() {
            const payload = {
                ...this.abnormalData,
                lifter_id: this.lifter_id
            };
            lifter_manage_lifter_error_create(payload)
                .then(() => {
                    this.$message.success('添加异常成功');
                    this.getLifterError(this.lifter_id); // 刷新异常列表
                    this.dialogErrorFormVisible = false;
                })
                .catch(error => {
                    this.$message.error(error);
                });
        },
        editAbnormal() {
            lifter_manage_lifter_error_update({
                id: this.editData.id,
                ...this.editData
            })
                .then(() => {
                    this.$message.success('编辑异常成功');
                    this.getLifterError(this.lifter_id); // 刷新异常列表
                    this.dialogErrorFormVisibleEdit = false;
                })
                .catch(error => {
                    this.$message.error(error);
                });
        },
        handleTableButton(row, action) {
            switch (action) {
                case 'toResolvent':
                    this.toResolvent(row);
                    break;
                case 'openEdit':
                    this.openEdit(row);
                    break;
                default:
                    break;
            }
        },
        handleTabClick() {
            // handle tab click event
        },
        toResolvent(row) {
            let payload = {
                ...row,
                lifterName: this.infoData.lifter_name
            };
            this.$router.push({
                name: 'lifter_Fault_Resolvent',
                query: payload
            });
        },
        openEdit(row) {
            this.editData = { ...row };
            this.dialogErrorFormVisibleEdit = true;
        }
    }
};
</script>

<style lang="scss" scoped>
/* 添加您的样式 */
</style>
